import React, { useContext, useEffect, useState } from "react";
import {
  ChevronCompactLeft,
  ChevronCompactRight,
  Pencil,
  PlusSquare,
} from "react-bootstrap-icons";
import { AppContext } from "../../App";
import { Header, Sidebar } from "../common";
import Currencies from "../common/CurrencyList/currencies.json";
import {
  BrokerData,
  MarketData,
  MarketsResponse,
  validateMarketForm,
  ValidationErrors,
} from "../common/Interface/types";
import { DiscardModal, Modal } from "../common/Modal";
import { usePaginationReducer } from "../common/useReducer/usePaginationReducer";
import { getData, postData, putData } from "../utils/api";
import { Form } from "./form";
import Spinner from "../common/Spinner";

type MarketInfo = {
  currency: string;
  currency_symbol: string;
  timezone: number;
};

type MarketDetails = {
  USA: MarketInfo;
  CRYPTO: MarketInfo;
  INDIA: MarketInfo;
};

const options = {
  timezone: [
    { label: "(GMT -5:00) Eastern Time", value: 1 },
    { label: "(GMT +0:00) UTC", value: 2 },
    {
      label: "(GMT +5:30) IST",
      value: 3,
    },
  ],
  name: [
    { label: "INDIA", value: 1 },
    { label: "USA", value: 2 },
    { label: "CRYPTO", value: 3 },
  ],
};

const marketDetails: MarketDetails = {
  INDIA: {
    currency: "INR",
    currency_symbol: "₹",
    timezone: 3,
  },
  USA: {
    currency: "USD",
    currency_symbol: "$",
    timezone: 1,
  },
  CRYPTO: {
    currency: "USDT",
    currency_symbol: "₮",
    timezone: 2,
  },
};

const itemsPerPage = 11;
const MarketList = () => {
  const { token, setAdmin, setToastMessage } = useContext(AppContext);
  const [formData, setFormData] = useState<MarketData>({
    name: "",
    currency_code: "",
    currency_symbol: "",
    time_zone: "",
    identifiers: [],
    position: 1,
  });
  const { state, setData, setPage, setTotal, setTotalPages } =
    usePaginationReducer();
  const totalPages = Math.ceil(state?.total / state?.itemsPerPage);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showErrors, setShowErrors] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [defaultModalOpen, setDefaultModelOpen] = useState(true);
  const [open, setOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [currentMarket, setCurrentMarket] = useState<MarketData | null>(null);
  const [loading, setLoading] = useState(true);
  const [brokerData, setBrokerData] = useState<BrokerData[]>([]);
  const [brokerOption, setBrokerOption] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!localStorage.getItem("authToken")) setAdmin(null);
  }, [setAdmin]);

  const handleMarketApi = async () => {
    setIsLoading(true);
    try {
      const data: MarketsResponse = await getData(
        `/admin/markets?page=${state.currentPage}&limit=${itemsPerPage}`,
        token,
      );
      const { data: markets, pageInfo } = data.markets;
      setData(markets || []);
      setTotal(pageInfo.total);
      setTotalPages(Math.ceil(pageInfo.total / state.itemsPerPage));
    } catch (error: any) {
      setToastMessage({
        status: error.status,
        text: error?.data || error?.message,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const handleBrokerApi = async (marketName?: string) => {
    if (token) {
      setIsLoading(true);
      try {
        const brokerData: any = await getData(`/admin/brokers`, token);
        setBrokerData(brokerData?.brokers || []);
        if (marketName) {
          updateBrokerOptionsForMarket(marketName);
        }
      } catch (error: any) {
        setToastMessage({
          text: error?.data || error?.message,
          type: "error",
        });
      }
      setIsLoading(false);
    }
  };

  const updateBrokerOptionsForMarket = (marketName: string) => {
    let filteredBrokers: any = [];
    if (marketName === "INDIA") {
      filteredBrokers = brokerData.filter((broker) => broker.identifier === 3);
    } else if (marketName === "USA" || marketName === "CRYPTO") {
      filteredBrokers = brokerData.filter((broker) =>
        [1, 2].includes(broker.identifier),
      );
    } else {
      filteredBrokers = [];
    }
    setBrokerOption(
      filteredBrokers.map((b: { name: string; identifier: number }) => ({
        label: b.name,
        value: b.identifier,
      })),
    );
  };

  const handleEditClick = (id: any) => {
    const marketObj = state?.data?.find((data: { id: any }) => data.id === id);
    if (marketObj) {
      setCurrentMarket(marketObj);
      const brokerNames = marketObj?.identifiers
        ?.map((identifier: any) => {
          const broker = brokerData.find(
            (brokerItem: any) => brokerItem?.identifier === identifier,
          );
          return broker
            ? { label: broker?.name, value: broker?.identifier }
            : null;
        })
        .filter((broker: any) => broker !== null);

      setFormData({
        name: marketObj?.name,
        currency_code: marketObj?.currency_code || "",
        time_zone: marketObj?.time_zone || "",
        currency_symbol: marketObj?.currency_symbol || "",
        identifiers: brokerNames,
        position: marketObj?.position,
      });
      updateBrokerOptionsForMarket(marketObj?.name);

      setIsModalVisible(true);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      currency_code: "",
      time_zone: "",
      currency_symbol: "",
      identifiers: [],
    });
    setErrors({});
    setShowErrors(false);
    setIsModalVisible(false);
    setCurrentMarket(null);
    setBrokerOption([]);
  };
  const getCurrencyLabel = (currency: { label: string }) => currency.label;

  const handleSelectChange = (param: string) => (selectedOption: any) => {
    setIsDirty(true);
    const currentPosition = formData.position ?? 1;
    const newPosition = currentPosition === 1 ? 1 : currentPosition + 1;
    const newFormData = {
      ...formData,
      [param]: selectedOption ? selectedOption.label : null,
      position: newPosition,
    };

    if (param === "name" && selectedOption) {
      const marketName = selectedOption.label as keyof MarketDetails;
      const marketInfo = marketDetails[marketName];

      if (marketInfo) {
        newFormData.currency_code = marketInfo.currency;
        newFormData.currency_symbol = marketInfo.currency_symbol;
        newFormData.time_zone =
          options.timezone.find(
            (timezone) => timezone.value === marketInfo.timezone,
          )?.label || "";
        updateBrokerOptionsForMarket(marketName);
      }
    }
    setFormData(newFormData);
    showErrors && setErrors({});
  };

  const handleBrokerSelect = (selectedBrokers: any[]) => {
    setIsDirty(true);
    const selectedBrokerIds = selectedBrokers
      ? selectedBrokers.map((broker) => broker.value)
      : [];
    setFormData((prev) => ({
      ...prev,
      identifiers: selectedBrokerIds,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateMarketForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setShowErrors(true);
      return;
    }
    setIsDirty(false);
    try {
      if (currentMarket) {
        await putData(`/admin/markets/${currentMarket.id}`, token, formData);
        setToastMessage({
          text: "Market name updated succesfully",
          type: "success",
        });
      } else {
        await postData("/admin/markets", token, formData);
        setToastMessage({
          text: "Market name added succesfully",
          type: "success",
        });
      }
      setDefaultModelOpen(false);
      handleMarketApi();
      handleBrokerApi();
      resetForm();
    } catch (error: any) {
      setToastMessage({
        text: error?.data || error?.message,
        type: "error",
      });
    }
  };
  const handleClose = () => {
    if (isDirty) {
      setOpen(true);
    } else {
      resetForm();
    }
  };
  const handleNoClick = () => {
    setOpen(false);
  };
  const handleYesClick = () => {
    setOpen(false);
    setIsModalVisible(false);
    setIsDirty(false);
    resetForm();
  };
  const onPageChange = (page: number) => {
    if (page > 0 && page <= state.totalPages) {
      setPage(page);
    }
  };
  const maxVisiblePages = 3;
  const startPage = Math.max(
    1,
    state?.currentPage - Math.floor(maxVisiblePages / 2),
  );
  const endPage = Math.min(state?.totalPages, startPage + maxVisiblePages - 1);
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      setLoading(false);
    };
    handleMarketApi();
    handleBrokerApi();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPage, state.itemsPerPage]);

  const currencyData = Object.entries(Currencies).map(([code, details]) => ({
    value: code,
    label: `${details.name} (${code}) - ${details.symbolNative}`,
    symbol: `${details.symbolNative}`,
  }));

  const handleCurrencySelect = (currency: {
    label: string;
    symbol: string;
  }) => {
    setIsDirty(true);
    setFormData((prev) => ({
      ...prev,
      currency_code: currency.label,
      currency_symbol: currency.symbol,
    }));
  };

  return (
    <>
      <Header />
      <main className="main">
        <Sidebar />
        <div className="app-main">
          {loading ? (
            <></>
          ) : (
            <div className="app-content d-flex flex-column">
              <div className="app-market-content flex-column flex-fill w-100">
                <div className="qt-market-header w-100">
                  <h2 className="mb-0">Markets</h2>
                </div>
                <div className="w-100 add-market-section">
                  {state?.total > 0 ? (
                    <div className="row g-3">
                      <div className="col-lg-3 col-sm-6 d-flex">
                        <div className="bg-dark-card w-100 d-flex">
                          <div className="qt-card qt-add bg-black">
                            <div className="qt-card-top d-flex justify-content-center flex-column bg-transparent">
                              <span className="btn btn-primary m-auto p-2">
                                <PlusSquare className="icon-xxl" />
                              </span>
                              <p className="mb-0">Add New Market</p>
                            </div>
                            {/* eslint-disable-next-line */}
                            <a
                              href="#"
                              className="stretched-link"
                              onClick={() => setIsModalVisible(true)}
                            ></a>
                          </div>
                        </div>

                        {isModalVisible && (
                          <Modal
                            title={
                              currentMarket ? "Update Market" : "Add Market"
                            }
                            onClose={handleClose}
                          >
                            <Form
                              handleSubmit={handleSubmit}
                              formData={formData}
                              dismissValue="modal"
                              error={errors}
                              currencyData={currencyData}
                              handleCurrencySelect={handleCurrencySelect}
                              handleBrokerSelect={handleBrokerSelect}
                              getCurrencyLabel={getCurrencyLabel}
                              options={options}
                              handleSelectChange={handleSelectChange}
                              brokerOption={brokerOption}
                            />
                          </Modal>
                        )}
                      </div>
                      {state?.data?.map((data: any) => (
                        <div className="col-lg-3 col-sm-6 d-flex" key={data.id}>
                          <div className="bg-dark-card w-100 border-custome-bottom">
                            <div className="qt-card bg-card-img">
                              <div className="qt-card-top">
                                <i className="i-market-up icon-lg m-auto d-block"></i>
                                <p className="mb-0">
                                  {data.name}{" "}
                                  {data?.currency_symbol &&
                                    `(${data?.currency_symbol})`}
                                </p>
                                <p>{data?.time_zone}</p>
                              </div>
                              <div className="action-bar">
                                <button
                                  className="btn btn-primary p-2 m-2 d-flex align-items-center justify-content-center"
                                  onClick={() => handleEditClick(data?.id)}
                                >
                                  <Pencil />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="bg-dark-card w-25 d-flex">
                        <div className="qt-card qt-add bg-black">
                          <div className="qt-card-top d-flex justify-content-center flex-column bg-transparent">
                            <span className="btn btn-primary m-auto p-2">
                              <PlusSquare className="icon-xxl" />
                            </span>
                            <p className="mb-0">Add Market</p>
                          </div>
                          {/* eslint-disable-next-line */}
                          <a
                            href="#"
                            className="stretched-link"
                            onClick={() => setDefaultModelOpen(true)}
                          ></a>
                        </div>
                      </div>
                      {defaultModalOpen && !isLoading && (
                        <Modal
                          title="Add Market"
                          onClose={() => setDefaultModelOpen(false)}
                        >
                          <Form
                            handleSubmit={handleSubmit}
                            formData={formData}
                            error={errors}
                            currencyData={currencyData}
                            handleCurrencySelect={handleCurrencySelect}
                            handleBrokerSelect={handleCurrencySelect}
                            getCurrencyLabel={getCurrencyLabel}
                            options={options}
                            handleSelectChange={handleSelectChange}
                            brokerOption={brokerOption}
                          />
                        </Modal>
                      )}
                      {isLoading && <Spinner />}
                    </>
                  )}
                </div>
              </div>
              {state?.total > 10 && (
                <div className="market-card-footer">
                  <p className="mb-0" style={{ marginTop: "7px" }}>
                    Showing {(state?.currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(state?.currentPage * itemsPerPage, state?.total)}{" "}
                    of {state?.total} records
                  </p>
                  <div className="d-flex">
                    <nav
                      aria-label="Page navigation"
                      style={{ marginLeft: "17px" }}
                    >
                      <ul className="market-pagination mb-0">
                        <li
                          className={`page-item ${state?.currentPage === 1 ? "disabled" : ""}`}
                        >
                          <button
                            className="page-link"
                            onClick={() => onPageChange(state?.currentPage - 1)}
                            disabled={state?.currentPage === 1}
                          >
                            <ChevronCompactLeft size={20} title="Previous" />
                          </button>
                        </li>
                        {startPage > 1 && (
                          <>
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() => onPageChange(1)}
                              >
                                1
                              </button>
                            </li>
                            {startPage > 2 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}
                          </>
                        )}
                        {pages.map((page) => (
                          <li
                            key={page}
                            className={`page-item ${state?.currentPage === page ? "active" : ""}`}
                          >
                            <button
                              className="page-link"
                              onClick={() => onPageChange(page)}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                        {endPage < totalPages && (
                          <>
                            {endPage < totalPages - 1 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() => onPageChange(totalPages)}
                              >
                                {totalPages}
                              </button>
                            </li>
                          </>
                        )}

                        <li
                          className={`page-item ${state?.currentPage === totalPages ? "disabled" : ""}`}
                        >
                          <button
                            className="page-link"
                            onClick={() => onPageChange(state?.currentPage + 1)}
                            disabled={state?.currentPage === totalPages}
                          >
                            <ChevronCompactRight size={20} title="Next" />
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
      {open && (
        <DiscardModal
          title="Discard Changes"
          onClose={handleNoClick}
          onYesClose={handleYesClick}
          children="would like to discard changes made?"
        />
      )}
    </>
  );
};
export default React.memo(MarketList);
