const Spinner = () => {
  return (
    <div
      className="modal-backdrop fade show"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1050,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        ></div>
      </div>
    </div>
  );
};

export default Spinner;
